<template>
  <v-container>
      <v-row>
          <v-subheader>Project that Assigned to You</v-subheader>
          <v-col md="12" sm="12">
            <v-card
                class="mx-auto"
                max-width="100%"
                outlined
                link
                to="/data_entry/entry_types"
                style="padding-top:3px"
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/project.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Details
                </div>
                <v-list-item-title class="text-h6 mb-1">
                    Digital Technology Institute
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
          <v-col md="12" sm="12">
            <v-card
                class="mx-auto"
                max-width="100%"
                outlined
                link
                to="/data_entry/entry_types"
                style="padding-top:3px"
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/project.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Details
                </div>
                <v-list-item-title class="text-h6 mb-1">
                    Development of National ICT Statistical Information Management System (NISMIS).
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
          <v-col md="12" sm="12">
            <v-card
                class="mx-auto"
                max-width="100%"
                outlined
                link
                to="/data_entry/entry_types"
                style="padding-top:3px"
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/project.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Details
                </div>
                <v-list-item-title class="text-h6 mb-1">
                    Innovation Center
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>